var Label = function(){

    //判断js是否被引入
    function isInclude(name){
        var js= /js$/i.test(name);
        var es=document.getElementsByTagName(js?'script':'link');
        for(var i=0;i<es.length;i++)
            if(es[i][js?'src':'href'].indexOf(name)!=-1)return true;
        return false;
    }


    // 动态加载js脚本文件
    function loadScript(url) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.body.appendChild(script);
    }

    // 动态加载css文件
    function loadStyles(url) {
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = url;
        document.getElementsByTagName("head")[0].appendChild(link);
    }

    Array.prototype.uniqueArry = function() {
        this.sort();
        var re = [this[0]];
        for (var i = 1; i < this.length; i++) {
            if (this[i] !== re[re.length - 1]) {
                re.push(this[i]);
            }
        }
        return re;
    }

    var obj = {},
        ele = $('body'),
        labelList = [],
        maxLength = 5,
        oldList = [],
        syLength = maxLength;
        selectLabel = [],
            layeros = null,
            isRead = false,
            layerIndex = null,
        baseUrl = "../../js/component/";
    obj.loadBase = function(callback){
                if($('#eleLable').length === 0 ){
                    $('body').append('<div id="eleLable" ></div>');
                }
                $('#eleLable').load(baseUrl+'label/index.html',function(){
                    if(callback){
                        callback();
                    }
                });
    };
    obj.init = function(config){
                  if(config.ele){
                      ele = config.ele;
                  }
                  if(config.labelList){
                      labelList = config.labelList;
                  }
                  if(config.maxLength){
                      maxLength = config.maxLength;
                  }

                  if ( config.isRead ){
                      isRead = config.isRead
                  }

                  if(config.oldList){
                      oldList = config.oldList;
                      for(var i=0;i<config.oldList.length;i++){
                          oldList[i].isSelected = true;
                          selectLabel.push(config.oldList[i]);
                      }
                  }


                  var callback = function(){
                      obj.renderAction();
                  };
                  obj.loadBase(callback);
    };
    obj.renderAction = function(){
        var data = {
            maxLength:maxLength
        };
        var _html = template('lableWrapperTpl',{data:data,isRead:isRead});
        ele.html(_html);
        ele.find('.J-label-btn').on('click',function(e){
            e.preventDefault();
            obj.renderLayerList();
        })
        if(oldList.length>0){
            obj.renderSelectLable();
        }
    };
    obj.renderLayerMaxLength = function(){
        var max = maxLength-selectLabel.length;
        layeros.find('.J-max').html(max);
    };
    obj.renderSelectLable=function(){
        var max = maxLength - selectLabel.length;
        ele.find('.cur').html(max);
        var _html = template('labelList',{list:selectLabel,isRead:isRead});
        ele.find('.label-selected').html(_html);
        var selectLId = [];
        for(var i=0;i<selectLabel.length;i++){
            selectLId.push(selectLabel[i].id)
        }
        ele.find('input[name="labelIds"]').val(selectLId.join(','));
        ele.find('.J-ul-label li').on('click',function(){
            if ( isRead ) return false
            var th = $(this),thId = parseInt(th.attr('data-id'));
            th.remove();
            max = max+1;
            for(var i=0;i<oldList.length;i++){
                if(thId === parseInt(oldList[i].id)){
                    oldList.splice(i,1);
                }
            }
            for(var i=0;i<selectLabel.length;i++){
                if(thId === parseInt(selectLabel[i].id)){
                    selectLabel.splice(i,1);
                }
            }

            for(var i=0;i<labelList.length;i++){
                if(thId === parseInt(labelList[i].id)){
                    labelList[i].isSelected = false;
                }
            }
            for(var i = 0;i<selectLId.length;i++){
                if(parseInt(selectLId[i]) === thId){
                    selectLId.splice(i,1);
                }
            }
            ele.find('.cur').html(max);
            ele.find('input[name="labelIds"]').val(selectLId.join(','));
        });
        oldList = [];
        for(var i=0;i<selectLabel.length;i++){
            oldList.push(selectLabel[i]);
        }
        for(var i=0;i<labelList.length;i++){
            for(var j=0;j<oldList.length;j++){
                if(parseInt(labelList[i].id) === parseInt(oldList[j].id) ){
                    labelList[i].isSelected = true;
                }
            }
        }
    };
    obj.reset=function(){
        selectLabel = [];
        for(var i=0;i<labelList.length;i++){
            labelList[i].isSelected = false;
        }
        if(oldList.length>0){
            for(var i=0;i<oldList.length;i++){
                selectLabel.push(oldList[i]);
            }

            for(var i=0;i<labelList.length;i++){
                for(var j=0;j<oldList.length;j++){
                    if(parseInt(labelList[i].id) === parseInt(oldList[j].id)){
                        labelList[i].isSelected = true;
                    }
                }
            }

        }
    };
    obj.renderLayerList = function(){
        var data = {
            maxLength:maxLength
        };
        var content = template('layerLabelTpl',{data:data,isRead:isRead});
        var layerList = template('labelList',{list:labelList,isRead:isRead});
       layui.use('layer',function(){
           layer.open({
               title:'Add Label',
               type: 1,
               skin:'labelLayer',
               resize:false,
               fixed:true,
               scrollbar:false,
               content:content,
               area:["660px"],
               btn: ['Cancel','Save'],
               success:function(layero,index){
                   layeros = layero;
                   layerIndex = index;
                   layer.style(index, {
                       height: '480px'
                   });
                   layero.find('.J-lable-box').html(layerList);
                   obj.renderLayerMaxLength();
                   layero.find('.J-ul-label li').on('click',function(e){
                       e.preventDefault();
                       var th = $(this),thId = parseInt(th.attr('data-id')),thName = th.attr('data-name'),thCur = th.hasClass('active');
                       if(thCur){
                           th.removeClass('active');
                           for(var i=0;i<selectLabel.length;i++){
                               if(thId === parseInt(selectLabel[i].id)){
                                   selectLabel.splice(i,1);
                               }
                           }
                       }else{
                           if(selectLabel.length < maxLength){
                               th.addClass('active');
                               selectLabel.push({
                                   id:thId,
                                   name:thName,
                                   isSelected:true
                               });
                               selectLabel = selectLabel.uniqueArry();
                               syLength = maxLength - selectLabel.length;
                           }


                       }
                       obj.renderLayerMaxLength();
                   });
               },
               yes:function(){
                  layer.close(layerIndex);
                   obj.reset();
               },
               btn2:function(){
                   // if(selectLabel.length === 0){
                   //     layer.msg('请至少选择一项');
                   //     return false;
                   // };
                   obj.renderSelectLable();
               },
               cancel:function(){
                   obj.reset();
               }
           })
       });
    };
    return obj;
};