
var uploaderAudio = function(){
    var obj = {},
        ele = null,
        inputName = null,
        fileList = [],
        callbackData = null,
        accept = null,
        audioUrl = "",
        uploadStart = null,
        audioSec = 0,
        TIMES = null,
        isuploaderYun = false,          //是否开启上传音频
        isAudioAutoplay = false,        //是自动播放音频
        msg = "Upload Audio Material",
        delFun = null;
        currentTimeArray = [];


    function getObjectURL(file) {
        var url = null;
        if (window.createObjectURL != undefined) {
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    }

    var baseUrl = "../../js/component/";


    obj.loadBase = function(callback){
        //加载基础的css、js、html
        if($('#uploaderAudio').length === 0){
            $('body').append('<div id="uploaderAudio"></div>');
            $('#uploaderAudio').load(baseUrl+'uploader/uploaderAudio.html',function(){
                if(callback){ callback() }
            });
        }else{
            if(callback){ callback() }
        }

    };

    obj.init = function(config){
        ele = config.ele;
        callbackData = config.callbackData;
        inputName = config.inputName !== undefined ? config.inputName:"audio";
        accept = config.accept !== undefined ? config.accept:"audio/mp3,audio/x-m4a";
        audioUrl = config.audioUrl !== undefined ? config.audioUrl:'';
        uploadStart = config.uploadStart !== undefined ? config.uploadStart:null;
        delFun = config.delFun !== undefined ? config.delFun:null;
        audioSec = config.audioSec !== undefined ? config.audioSec:0;
        isuploaderYun = config.isUploader?config.isUploader:false;
        isAudioAutoplay = config.isAudioAutoplay?config.isAudioAutoplay:false;
        msg = config.msg ? config.msg: 'Upload Audio Material';
        var callback = function(){
            obj.renderWrapper();
        };
        obj.loadBase(callback);


    };

    //计算时间
    obj.audioTime = function (times,curr) {
        curr = parseInt(curr) || 0;
        times= parseInt(times) - curr
        var count = times%60>=10? times%60:'0'+times%60;
        return '0'+Math.floor(times/60)+':'+count
    }

    //停止所有音频
    obj.stopAllAudio = function (fn) {
        var audios = document.querySelectorAll('audio')
        for(var i =0 ;i<audios.length;i++){
            var item = audios[i]
            item.pause()
            $(item).parent('.audioBtn').removeClass('play')
            clearInterval(TIMES)
        }
        fn&&fn()
    }

    function isRepeat(arr){
        var hash = {};
        for(var i in arr) {
            if(hash[arr[i]])
                return true;
            hash[arr[i]] = true;
        }
        return false;

    }

    //音频进度条控制
    obj.progressFn = function () {
        var progress = ele.find('.progress>span'),
            time = ele.find('.time'),
             audioBtn = ele.find('.audioBtn'),
            audio = ele.find('.audio').get(0)
        TIMES =  setInterval(function () {
            var duration = audio.duration
            var currentTime = audio.currentTime
            var barWidth = currentTime/duration*100?currentTime/duration*100:0;
            console.log(duration,currentTime);
            currentTimeArray.push(currentTime);
            var isRepeats = isRepeat(currentTimeArray);
            if(duration-currentTime !== 0 && isRepeats === false ){
                progress.css('width',barWidth+'%')
                time.text(obj.audioTime(duration,currentTime))
            }else {
                clearInterval(TIMES)
                progress.css('width','0%')
                audioBtn.removeClass('play')
                time.text(obj.audioTime(duration))
            }
        },100)
    }

    //播放
    obj.play = function (callback) {
        var audio = ele.find('audio').get(0)
        var audioBtn = ele.find('.audioBtn')
        audioBtn.unbind().on('click',function () {
                currentTimeArray = [];
            if(!audio.paused){
                audioBtn.removeClass('play')
                if(TIMES){
                    clearInterval(TIMES)
                }
                audio.pause()
            }else {
                obj.stopAllAudio(function () {
                    audioBtn.addClass('play')
                    audio.play()
                    obj.progressFn()
                })
            }
        })
        if(isAudioAutoplay){
            ele.find('.audioBtn').click();
        }
        if (callback) callback();
    }

    obj.renderWrapper = function(url){
        var data = {
            accept:accept,
            inputName:inputName,
            msg: msg
        };
        var _html = template('upAudioWrapper',{data:data,isuploaderYun:isuploaderYun});
        ele.append(_html);
        if(isuploaderYun){
            ele.find('.uplaoderAudio').on('change',function(e){
                e.preventDefault();
                var file = this.files[0];
                var callbacks = function () {
                    var uid = file.name+file.size;
                    var audioUrl = getObjectURL(file);
                    var curItem = {
                        uid:uid,
                        title:file.name,
                        localUrl:audioUrl,
                        file:file
                    };
                    if(uploadStart !== null){
                        uploadStart();
                    }
                    if(fileList.length === 0){
                        fileList.push(curItem);
                    }else{
                        var flag = true;
                        for(var i=0;i<fileList.length;i++){
                            if(fileList[i].uid === uid){
                                flag = false;
                            }
                        }
                        if(flag){
                            fileList.push(curItem);
                        }
                    }
                    var _htmlAudioPlay = template('audioTpl',{audioUrl:audioUrl,
                        audioSec:audioSec?audioSec:0,
                        isuploaderYun:isuploaderYun});
                    ele.find('.upload-btn').hide();
                    ele.find('.J-audio-player').html(_htmlAudioPlay).css('visibility','visible');
                    ele.find('.J-del').on('click',function(e){
                        e.preventDefault();
                        currentTimeArray = [];
                        ele.find('.J-audio-player').css('visibility','hidden');
                        ele.find('.upload-btn').find('input[type="file"]').val('');
                        ele.find('.upload-btn').show();
                        ele.find('input[name="audio"]').val('');
                        ele.find('input[name="audioSec"]').val('');
                        if(delFun !== null){
                            delFun();
                        }
                    })
                    var audio = ele.find('.audio').get(0);
                    audio.addEventListener("canplaythrough",function(){
                        if(audio.duration < 30 || audio.duration>60){
                            ele.find('.J-del').click();
                            toast.warming('Audio self-introduction is limited between 30 and 60 seconds.');
                            return false;
                        }
                        ele.find('.J-progress').progressCircle({
                            nPercent        :1,
                            showPercentText : true,
                            thickness       : 3,
                            circleSize      : 48
                        });
                        obj.uploaderService(file,function(data){
                            ele.find('input[name="audio"]').val(data);
                            ele.find('input[name="audioSec"]').val(parseInt(audio.duration));
                            if (callbackData){ callbackData(data) }
                        });
                        ele.find('.time').text(obj.audioTime(audio.duration))
                        obj.play()
                    })
                };
                uploadFlog(callbacks)
            })
        }


        if(audioUrl !== ""){
            var _htmlAudioPlay = template('audioTpl',{audioUrl:audioUrl,
                audioSec:audioSec?audioSec:0,
                isuploaderYun:isuploaderYun});
            ele.find('.upload-btn').hide();
            ele.find('.J-audio-player').html(_htmlAudioPlay).css('visibility','visible');
            var audio = ele.find('.audio').get(0);
            audio.addEventListener("canplaythrough",function(){
                ele.find('.time').text(obj.audioTime(audio.duration))
                obj.play()
                if(isuploaderYun){
                    ele.find('.J-del').on('click',function(e){
                        e.preventDefault();
                        ele.find('.J-audio-player').css('visibility','hidden');
                        ele.find('.upload-btn').find('input[type="file"]').val('');
                        ele.find('.upload-btn').show();
                        ele.find('input[name="audio"]').val('');
                        ele.find('input[name="audioSec"]').val('');
                    })
                }
            })


        }

    };


    obj.renderFileList = function(){
        var _html = template('upAudioList',{list:fileList});
        ele.find('.J-list').html(_html);
    }

    obj.uploaderService = function (file,callback) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e){
            var res = this.result;
            var fileSteams = res.split(',')[1];
            var formData = new FormData();
            formData.append('file', fileSteams);
            var orgInfo = store.get('teacher_userDate') !== undefined ? JSON.parse(store.get('teacher_userDate')):''
            var orgId = orgInfo.userId !== undefined ? orgInfo.userId+"/":'';
            var timestamp = (new Date()).valueOf();
            var property = obj.getProperty(file);
            var storeAs = 'teacherInfo/'+orgId+'certification/';
            formData.append('path',storeAs);
            var nows = new Date();
            var imgName = 'icoachu_backend_'+Date.parse(nows)+ '.' +property;
            formData.append('fileName',imgName);
            ajaxFun.icoachuUpdateFile('/common/upload','post',formData,function(data){
                var url = data.data.url;
                ele.find('.J-progress').hide();
                if (callback){ callback(url) }
            },function(progress){
                console.log(progress);
                $('.J-progress').progressCircle({
                    nPercent        :progress,
                    showPercentText : true,
                    thickness       : 3,
                    circleSize      : 48
                });
            })
        }

    }

    obj.getProperty = function (file) {
        var property = "";
        switch (file.type){
            case 'image/png':
                property='png';
                break;
            case 'image/jpeg':
                property='jpg';
                break;
            case 'image/jpg':
                property='jpg';
                break;
            case 'audio/mp3':
                property='mp3';
                break;
            case 'audio/x-m4a':
                property='m4a';
                break;
            case 'audio/mp4':
                property='mp4';
                break;
            case 'video/mp4':
                property='mp4';
                break;
            case 'application/vnd.ms-powerpoint':
                property='ppt';
                break;
            case 'application/msword':
                property='doc';
                break;
            case 'application/vnd.ms-excel':
                property='xls';
                break;
        };
        return property
    }

    obj.uploaderYun = function(file,callback) {
        // var fileType = file.type;
        // if(fileType.indexOf('video')>-1){
        //     folderStr = "video/";
        // }else if(fileType.indexOf('pdf')>-1 || fileType.indexOf('ppt')>-1 || fileType.indexOf('word')>-1 || fileType.indexOf('excel')>-1  || fileType.indexOf('text')>-1  ){
        //     folderStr = "ppt/";
        // }else if(fileType.indexOf('image')>-1 ){
        //     folderStr = "images/";
        // }else{
        //     folderStr = "";
        // }
        //

        var orgInfo = store.get('teacher_userDate') !== undefined ? JSON.parse(store.get('teacher_userDate')):''
        var orgId = orgInfo.userId !== undefined ? orgInfo.userId+"/":'';
        var timestamp = (new Date()).valueOf();
        var property = obj.getProperty(file);
        var storeAs = 'OnlineDataBase/web/teacherInfo/'+orgId+'certification/'+timestamp + '.' +property;

        var progress = function (p) {
            return function (done) {
                $('.J-progress').progressCircle({
                    nPercent        :parseInt(p*100),
                    showPercentText : true,
                    thickness       : 3,
                    circleSize      : 48
                });
                done();
            }
        };
        uploadInit(function (client) {
            var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
            var res = ''
            for (let i = 0; i < 8; i++) {
                var id = Math.ceil(Math.random() * 35)
                res += chars[id]
            }

            var formData = new FormData();
            formData.append('file', file);
            formData.append('path', "teacherWeb/images");
            var nows = new Date();
            var imgName = 'icoachu_backend_' + Date.parse(nows) +'_'+ res+'_'+file.name;
            formData.append('fileName', imgName);

            ajaxFun.icoachuUpdateFile('common/aliyun/upload', 'post', formData, function(data) {
                if (data && data.code === 0) {

                    var onlineUrl = data.data.urlList[0];
                    console.log(onlineUrl);
                    if(callback) callback(onlineUrl);
                    if(callbackData) callbackData(onlineUrl);
                    ele.find('.J-progress').hide()
                }
            })
        })
    };

    return obj;
};
