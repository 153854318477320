
var uploaderImg = function(){
    var obj = {};
    function getObjectURL(file) {
        var url = null;
        if (window.createObjectURL != undefined) {
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    };
    var imgList = [],
        ossUrl = [],
        maxs = 5,
        ele = null,
        callbackData = null,
        inputName = null,
        isDelete = true,
        baseUrl = "../../js/component/",
        delFun = null,
        msg = null,
        isAdd = true,
        uploadCallback = null;
    obj.init = function(config){
        ele = config.ele;
        callbackData = config.callback;
        uploadCallback = config.uploadCallback;
        inputName = config.inputName;
        baseUrl = config.baseUrl !== undefined ? config.baseUrl:"../../js/component/";
        var oldImglist = config.imgList !== undefined ? config.imgList:[];
        if(oldImglist.length>0){
            imgList = [];
            ossUrl = [];
            for(var i=0;i<oldImglist.length;i++){
                var cur = oldImglist[i];
                imgList.push(cur);
                ossUrl.push(cur);
            }
        }

        if( config.isDelete !== undefined && config.isDelete === false){
            isDelete = false;
        }
        if(config.max){
            maxs = config.max;
        }

        if( config.isAdd !== undefined && config.isAdd === false){
            isAdd = false;
        }

        if(imgList.length >0 && imgList.length >= maxs){
            isAdd = false;
        }

        if(config.delFun){
            delFun = config.delFun
        }

        if(config.msg){
            msg = config.msg;
        }
        var proJs = isInclude('promise-6.1.0.js');
        $('body').append('<div id="uploaderImg" ></div>');
        if(proJs === false){
            // loadScript('https://www.promisejs.org/polyfills/promise-6.1.0.js');
            // loadScript('https://gosspublic.alicdn.com/aliyun-oss-sdk.min.js');
            $('#uploaderImg').load(baseUrl+'uploader/uploader.html',function(){
                obj.renderWrapper();
            });
        }else{
            $('#uploaderImg').load(baseUrl+'uploader/uploader.html',function(){
                obj.renderWrapper();
            });
        }
    }
    obj.renderWrapper = function () {
        var _html = template('upImgWrapper',{inputName:inputName,isAdd:isAdd,msg:msg});
        $(ele).html(_html);
        obj.renderList();
    };
    obj.onChange = function(){
        ele.find(".uplaoderImg").unbind('change').on("change",function(){
            var file = this.files[0];
            //console.log(file);
            var fileType=file.type;
            if(fileType.indexOf('jpg')>-1 || fileType.indexOf('jpeg')>-1 || fileType.indexOf('png')>-1){

            }else{
                toast.error("仅支持大小2M以下的PNG/JPG格式图片");
                return false;
            }
            var callbacks = function () {
                if(file === undefined){
                    return false;
                }
                var uid = file.name+file.size;
                var imgUrl = getObjectURL(file);
                var callback = function (url) {
                    var objs = {
                        url:url,
                        uid:uid,
                        uName:file.name
                    };

                    var flage = true;
                    if(ossUrl.length>0){
                        for(var i=0;i<ossUrl.length;i++){
                            if(ossUrl[i].uid === uid){
                                flage = false;
                            }
                        }
                    }
                    if(flage){
                        ossUrl.push(objs);
                        obj.renderList();
                    }

                };
                var done = function(){
                    var objItem = {url:imgUrl,p:0,uid:uid};
                    var flage = true;
                    if(imgList.length>0){
                        for(var i=0;i<imgList.length;i++){
                            if(imgList[i].uid === uid){
                                flage = false
                            }
                        }
                    }
                    if(flage){
                        imgList.push(objItem);
                        obj.renderList();
                    }

                };
                var renderProgeress = function(p){
                    if(imgList.length>0){
                        for(var i=0;i<imgList.length;i++){
                            if(imgList[i].url === imgUrl){
                                imgList[i].p = p+"%";
                            }
                        }
                        obj.renderList();
                    }
                };
                obj.uploaderYun(file,done,renderProgeress,callback);
            };
            uploadFlog(callbacks)
        });
    }
    obj.restUploader = function(){
        imgList = [];
        ossUrl = [];
        isDelete = true;
        isAdd = true;
    };
    obj.renderList = function(){
        //debugger
        var _html = template('upImgList',{list:imgList,isDelete:isDelete,isAdd:isAdd,inputName:inputName});
        ele.find('.J-list').html(_html);
        obj.onChange()
        var resultUrl = [];
        if(callbackData){
            if(ossUrl.length>0){
                for(var i=0;i<ossUrl.length;i++){
                    resultUrl.push(ossUrl[i].url);
                }
            }
            callbackData(resultUrl);
        }

        if(inputName){
            resultUrl = [];
            if(ossUrl.length>0){
                for(var i=0;i<ossUrl.length;i++){
                    resultUrl.push(ossUrl[i].url);
                }
            }
            ele.find('input[name="'+inputName+'"]').val(resultUrl.join(','));
        }

        if(isDelete){
            if(imgList.length<maxs){
                isAdd = true;
                ele.find('.upload').css('display','inline-block');
            }else{
                isAdd = false;
                ele.find('.upload').css('display','none');
            }
        }

        //删除
        ele.find('.J-list .J-del').unbind('click').on('click',function(e){
            e.preventDefault();
            var th = $(this),thUid = th.attr('data-uid'),thUrl = th.attr('data-url');
            if(ossUrl.length>0){
                for(var i=0;i<ossUrl.length;i++){
                    if(ossUrl[i].uid.toString() ===thUid.toString()){
                        ossUrl.splice(i,1)
                    }
                }
            }
            if(imgList.length>0){
                for(var i=0;i<imgList.length;i++){
                    if(imgList[i].uid.toString() === thUid.toString()){
                        imgList.splice(i,1);
                    }
                }
            }

            if(delFun !== null){
                delFun();
            }

            obj.renderList();

        });

        ele.find('.J-photo').unbind('click').on('click',function(e){
            e.preventDefault();
            var th = $(this);
            var thUrl = th.attr('data-src'),title = th.attr('data-title');
            layer.photos({
                photos:{
                    "title": title, //相册标题
                    "id": 123, //相册id
                    "start": 0, //初始显示的图片序号，默认0
                    "data": [   //相册包含的图片，数组格式
                        {
                            "alt": title,
                            "pid":'', //图片id
                            "src": thUrl, //原图地址
                            "thumb": "" //缩略图地址
                        }
                    ]
                }
            });
        })
    };
    obj.uploaderYun = function(file,done,renderProgeress,callback) {
        var lim=$(".loading-img-mask");
        if(lim){
            lim.show();
        }
        if(done) done();
        var fileType = file.type;
        //debugger
        if(fileType.indexOf('video')>-1){
            folderStr = "video/";
        }else if(fileType.indexOf('pdf')>-1 || fileType.indexOf('ppt')>-1 || fileType.indexOf('word')>-1 || fileType.indexOf('excel')>-1  || fileType.indexOf('text')>-1  ){
            folderStr = "ppt/";
        }else if(fileType.indexOf('image')>-1 ){
            folderStr = "images/";
        }else{
            folderStr = "";
        }
        var orgInfo = store.get('teacher_userDate') !== undefined ? JSON.parse(store.get('teacher_userDate')):''
        var orgId = orgInfo.userId !== undefined ? orgInfo.userId+"/":'';
        var storeAs = 'OnlineDataBase/web/orgInfo/'+orgId+'certification/'+folderStr + new Date().getTime()  + '.' + fileType;

        var progress = function (p) {
            return function (done) {
                renderProgeress(p*100);
                done();
            }
        };
        uploadInit(function(client){
            var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
            var res = ''
            for (let i = 0; i < 8; i++) {
                var id = Math.ceil(Math.random() * 35)
                res += chars[id]
            }

            var formData = new FormData();
            formData.append('file', file);
            formData.append('path', "teacherWeb/images");
            var nows = new Date();
            var imgName = 'icoachu_backend_' + Date.parse(nows) +'_'+ res+'_'+file.name;
            formData.append('fileName', imgName);

            ajaxFun.icoachuUpdateFile('common/aliyun/upload', 'post', formData, function(data) {
                if (data && data.code === 0) {


                    var property = "";
                    switch (file.type){
                        case 'image/png':
                            property='png';
                            break;
                        case 'image/jpeg':
                            property='jpg';
                            break;
                        case 'image/jpg':
                            property='jpg';
                            break;
                        case 'audio/mpeg':
                            property='mp3';
                            break;
                        case 'audio/mp4':
                            property='mp4';
                            break;
                        case 'video/mp4':
                            property='mp4';
                            break;
                        case 'application/vnd.ms-powerpoint':
                            property='ppt';
                            break;
                        case 'application/msword':
                            property='doc';
                            break;
                        case 'application/vnd.ms-excel':
                            property='xls';
                            break;

                    };
                    var onlineUrl = data.data.urlList[0];
                    uploadCallback&&uploadCallback()
                    if(callback) callback(onlineUrl);
                }
            })


        })


    };
    return obj;
};
