//选择素材
$.ajaxSetup ({ cache: false });
var selectCourse = function () {
    var obj = {};
    var pageNumber = 1
        ,pageSize = 5
        ,folderAry = []
        ,checkItem = []
        ,layDom = null
        ,layIndex = null
        ,saveCallback = null
        ,eleOutPut = null
        ,eleBtns=null
        ,oldCheckedItem = []
        ,eleBtn = null
        ,oldList = null
        ,eleOutPutNum = null
        ,tempData = []
        ,param = null
        ,params = null
        ,maxNum = 5
        ,isRead = false
        ,isShowOutPut = false
        ,isOrg = false
        ,hasOrg = false
        ,property = ""
        ,renderCallback = null
        ,baseUrl = "../../js/component/"
        ,modalTitle = "关联课件"
        ,delFun = null;
    obj.init=function ( config ) {
        eleBtn = config.eleBtn !== undefined ? config.eleBtn:null;
        eleOutPut = config.eleOutPut !== undefined ? config.eleOutPut:null;
        saveCallback = config.saveCallback !== undefined ? config.saveCallback:null;
        oldList = config.oldList !== undefined ? config.oldList:[];
        delFun = config.delFun !== undefined ? config.delFun:null;
        maxNum = config.maxNum !== undefined ? config.maxNum:5;
        eleOutPutNum = config.eleOutPutNum !== undefined ? config.eleOutPutNum:null;
        param = config.param !== undefined ? config.param:null;
        params = config.params !== undefined ? config.params:null;
        modalTitle = config.modalTitle !== undefined ? config.modalTitle:null;
        isShowOutPut = config.isShowOutPut !== undefined ? config.isShowOutPut : false;
        property = config.property !== undefined ? config.property:'';
        renderCallback = config.renderCallback !== undefined ? config.renderCallback:null;
        if(eleOutPutNum !== null && oldList.length>0){
            eleOutPutNum.html(oldList.length);
        }
        isRead = config.isRead !== undefined ? config.isRead : false;
        if(oldList !== undefined){
            for(var i=0;i<oldList.length;i++){
                tempData.push(oldList[i]);
            }
        }
        if($('#kejian').length === 0){
            $('body').append('<div id="kejian" ></div>');
            untils.loadStyles('../../js/swiper/swiper-4.1.0.min.css')
            untils.loadScript('../../js/swiper/swiper-4.1.0.min.js');
        }
        $('#kejian').load(baseUrl+'selectCourse/index.html',function(){
            obj.startFun()
        });
        // $('#kejian').load(baseUrl+'chooseCourseware/index.html',function(){
        //
        // });
    };
    obj.orgFile = function(callback){
        var userDate=store.get("teacher_userDate") !== undefined ? JSON.parse(store.get("teacher_userDate")):{};
        if(userDate.freedomStatus === 3 && userDate.orgMember &&  userDate.orgMember.roleType.indexOf('0') > -1 ){
            hasOrg = true
        }else{
            hasOrg = false;
        }
        if(callback){
            callback();
        }
    };
    obj.startFun = function () {
        obj.orgFile();
        if(oldList !== null){
            checkItem = oldList;
            oldCheckedItem = oldList;
            if(renderCallback){
                obj.renderSaveFile(renderCallback(oldList,eleBtn,eleOutPut));
            }else{
                obj.renderSaveFile();
            }

        }
        var iou_param = {pageNumber:pageNumber,pageSize:pageSize};
        obj.initLayer(iou_param);
    };
    obj.tabClick = function(){
        $('.J-tab a').on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            var th = $(this),type = parseInt(th.attr('data-type'));
            $('.J-tab a').removeClass('active');
            th.addClass('active');
            if(type === 0){
                isOrg = false;
            }else{
                isOrg = true;
            }
            var iou_param = {
                pageSize:pageSize,
                pageNumber:1
            };
            var callback = function (data) {
                obj.renderList(data,iou_param);
            };

            obj.getFileList(iou_param,callback);
        });
    };
    obj.initLayer=function (iou_param) {
        if(eleBtn){
            eleBtn.unbind('click').on('click',function (e) {
                var th = $(this),thTitle = th.attr('data-title');
                if (thTitle) modalTitle = thTitle;
                var param = {pageNumber:pageNumber,pageSize:pageSize};
                var callback = function(data){
                    var _html = obj.renderChooseWrapper(data);
                    var callback = function () {
                        if(hasOrg){
                            $('.J-tab').show();
                            obj.tabClick();
                        }
                        //
                        data.folderAry = folderAry;
                        /* obj.renderBread(data,iou_param);
                         obj.folderClick(iou_param);*/
                        obj.renderList(data,iou_param);
                        //展示page
                        var elePage = $(layDom).find('#page'),
                            pageCount = data.pages,
                            curPage = data.pageNumber,
                            callbacks = function (curpage) {
                                var callback = function (data) {
                                    obj.renderList(data,iou_param);

                                };
                                iou_param.pageNumber = curpage;
                                obj.getFileList(iou_param,callback)
                            };
                        obj.getPage(elePage,pageCount,curPage,callbacks);
                    };
                    obj.layeropen(_html,callback)
                }
                obj.getFileList(param,callback);

            })
        }

    };
    obj.renderChooseWrapper=function (data) {
        //渲染弹出层里面的内容
        var _html = template('fileList',{list:data.items});

        return _html
    };
    obj.getFileList=function (param,callback) {
        var courseid=params.courseId,keyword=params.keyword,exptype=params.expType,expcosId="",cosid="";
        if(exptype===0){
            expcosId=courseid;
        }else{
            cosid=courseid;
        }
        //debugger
        var iou_url='course/relation/list',
            iou_param={
                "courseType":1,//课程类型,0.官方,1.自建
                "keyword":keyword,
                "classType":"",//班级类型：0：1对1 ，1小班课，2大班课
                "pageNumber":param.pageNumber,
                "pageSize":param.pageSize,
                "searchType":'',//查询类型0官方课1自建课2机构课
                "sort":"",//排序字段
                "expType":exptype,//课程类型：0体验1正式
                "expCosId":expcosId,
                "categoryId":"",//课程分类
                "cosId":cosid,
                "keyword":param.roomMaterialName
            };
        if(property !== ""){
            iou_param.property = property.toLocaleLowerCase();
        }
        var callbacks = function(data){
            if(data&&data.code === 0){
                var datas = data.data;
                if(callback) callback(data.data,iou_param);
            }else{
                toast.error(data.msg);
            }
        };
        if( isOrg === false ){
            ajaxFun.icoachuSelectAjax(iou_url,'get',iou_param,callbacks,{isLoading:false});
        }else{
            ajaxFun.icoachuSelectOrgAjax(iou_url,'get',iou_param,callbacks,{isLoading:false});
        }

    };
    obj.renderList=function (data,iou_param) {
        var itemss = data.items;
        console.log(checkItem);
        if(itemss !== undefined && itemss.length>0){
            for(var i=0;i<itemss.length;i++){
                if(itemss[i].checked !== undefined){
                    delete itemss[i].checked;
                }
            }
            if(checkItem.length>0){
                for(var i=0;i<checkItem.length;i++){
                    for(var j=0;j<itemss.length;j++){
                        if(itemss[j].id === parseInt(checkItem[i].id) ){
                            itemss[j].checked = true;
                            itemss[j].editFlag=checkItem[i].editFlag;
                        }
                    }
                }
            }
        }
        if(itemss.length>0){
            layDom.find('.mask-header-auto').show();
            layDom.find('.empty-style').hide();
        }else{
            layDom.find('.mask-header-auto').hide();
            layDom.find('.empty-style').show();
        }
        for(var i=0;i<itemss.length;i++){
            itemss[i].createTimeStr=timeHtml(itemss[i].createTime,'YY-MM-DD-HH-MM-SS');
        }
        //console.log(itemss);
        var _html = template('listTemp',{list:itemss});
        layDom.find('#orgWrapper').remove();
        if(isOrg){
            if(layDom.find('.J-list').length === 0){
                layDom.find('.empty-style').parent().append('<div id="orgWrapper"></div>');
                layDom.find('#orgWrapper').html(template('WrapperList',{list:itemss}));
            }else{
                layDom.find('.J-list').html(_html);
            }

        }else{
            layDom.find('.J-list').html(_html);
        }
        if(data&&data.items !== undefined){
            var elePage = $(layDom).find('#page'),
                pageCount = data.pages,
                curPage = data.pageNumber,
                callbacks = function (curpage) {
                    var callback = function (data) {
                        obj.renderList(data,iou_param);
                    };
                    iou_param.pageNumber = curpage;
                    var folderIds = untils.GetQueryString('folderId');
                    if (folderIds !== undefined && folderIds !== null){
                        iou_param.folderId = folderIds
                    }
                    obj.getFileList(iou_param,callback)
                };
            obj.getPage(elePage,pageCount,curPage,callbacks);
            obj.folderClick(iou_param);
            data.folderAry = folderAry;
            obj.renderBread(data,iou_param);
            obj.renderCheckBox(data,iou_param);
            obj.renderCheckAll(data);
            obj.renderFooter();
            if(isOrg){
                $('#orgWrapper').prev().hide()
            }
        }
    };
    obj.renderCheckBox=function (data,iou_param) {
        //使全选按钮不可点
       // $('.J-checkAll').prop('disabled',true);
      //  $('.J-checkAll').prop('checked',false);
        var datas = data.items;
        /*
        if(datas&&datas.length>0){
            for(var i = 0;i<datas.length;i++){
                if(datas[i].isFolder === 0){
                    $('.J-checkAll').prop('disabled',false);
                    break;
                }
            }
        }
        */


        //全选
        layDom.find('.J-checkAll').unbind('click').on('click',function (e) {
            var v = e.target.checked;
            if(v){
                if(checkItem.length === 0){
                    for(var i=0;i<datas.length;i++){
                        if(checkItem.length < maxNum){
                            if(datas[i].editFlag === false){
                                return false;
                            }
                            checkItem.push(datas[i]);
                        }
                    }
                }else{
                    for(var i=0;i<checkItem.length;i++){
                        for(var j=0;j<datas.length;j++){
                            if(checkItem.length < maxNum  ){
                                if(parseInt(checkItem.id) === parseInt(datas[j].id) ){

                                }else{
                                    if(datas[i].editFlag === false){
                                        return false;
                                    }
                                    checkItem.push(datas[j]);
                                }
                            }
                        }
                    }
                }
            }else{
                for(var i=0;i<datas.length;i++){
                        datas[i].checked = false;
                        for(var j = 0;j<checkItem.length;j++){
                            if(datas[i].id === checkItem[j].id ){
                                checkItem.splice(j,1);
                            }
                        }

                }
            }
            data.items = datas;
            obj.renderList(data,iou_param)
        })
        //单选
        layDom.find('.J-checkItem').unbind('click').on('click',function (e) {
            var v = e.target.checked;
            var values = e.target.value;
            var item = {};
            for(var i=0;i<datas.length;i++){
                if(parseInt(datas[i].id) === parseInt(values)){
                    item = datas[i];
                    if(v){
                        datas[i].checked = true
                    }else{
                        datas[i].checked = false;
                    }
                }
            }
            if(v){
                if(checkItem.length < maxNum){
                    checkItem.push(item);
                }
            }else{
                for(var i = 0; i<checkItem.length;i++){
                    if(parseInt(checkItem[i].id) === parseInt(item.id) ){
                        checkItem.splice(i,1);
                    }
                }
            }
            obj.renderList(data,iou_param)
        })

        //搜索
        layDom.find('.J-search-btn').unbind('click').on('click',function(e){
            e.preventDefault();
            var searchText = layDom.find('.J-search').val();
            // if(searchText === undefined || searchText === ""){
            //     toast.warming('请输入关键字');
            // }else{
            layDom.find('.search-box').addClass('active');
            searchText =  $.trim(searchText);
            var  iou_param={
                "roomMaterialName":searchText,
                "pageNumber":1,
                "pageSize":pageSize
            };
            if(folderAry.length>0){
                iou_param.folderId = folderAry[folderAry.length-1].folderId;
            }
            var callback = function (data) {
                obj.renderList(data,iou_param);

            };
            obj.getFileList(iou_param,callback);
            // }
        })
        layDom.find('.J-reset-search').unbind('click').on('click',function(e){
            e.preventDefault();
            layDom.find('.search-box').removeClass('active');
            layDom.find('.J-search').val('');
            var  iou_param={
                "pageNumber":1,
                "pageSize":5
            };
            if(folderAry.length>0){
                iou_param.folderId = folderAry[folderAry.length-1].folderId;
            }
            var callback = function (data) {
                obj.renderList(data,iou_param);
            };
            obj.getFileList(iou_param,callback);

        })


    };
    obj.renderCheckAll=function (data) {
        layDom.find('.J-checkAll').prop('checked',false);
        var datas = data.items !== undefined ? data.items:[] ;
        var isAllNumb  = 0,isFile = 0;
        for(var i= 0;i<checkItem.length;i++){
            for(var j =0; j<datas.length;j++){
                if(parseInt(checkItem[i].id ) ===  parseInt( datas[j].id )  ){
                    isAllNumb++;
                }
            }
        }

        for(var j = 0;j<datas.length;j++){
                isFile++;
        }
        //console.log(isAllNumb,isFile);
        if(isAllNumb === isFile && isFile !== 0 && isAllNumb !== 0){
            layDom.find('.J-checkAll').prop('checked',true);
        }
    };
    obj.renderFooter=function () {
        // console.log(checkItem);
        layDom.find('.J-num').text(maxNum-checkItem.length);
        layDom.find('#btn02').unbind('click').on('click',function () {
            obj.resetLayer(layIndex);

        });
        layDom.find('#btn01').unbind('click').on('click',function () {
            //确定
            var _new=[];
            if(checkItem.length  === 0){
                toast.warming('请选择课件');
            }else{
                for(var i=0;i<checkItem.length;i++){
                    if(checkItem[i].editFlag!==false){
                        _new.push(
                            checkItem[i]
                        )
                    }
                }

                checkItem = _new;
                //debugger

                //console.log(checkItem);
                var callback = function(){
                    if(saveCallback !== null){
                        if(param !== null){
                            saveCallback(checkItem,eleBtn,param,tempData);
                        }else{
                            saveCallback(checkItem,eleBtn,tempData);
                        }

                    }

                    if(eleOutPutNum !== null){
                        eleOutPutNum.html(checkItem.length);
                    }
                    tempData = [];
                    for(var i=0;i<checkItem.length;i++){
                        tempData.push(checkItem[i]);
                    }
                    obj.saveClose(layIndex);
                    if(checkItem.length === maxNum && isShowOutPut == false ){
                        eleBtn.hide()
                    }
                    eleOutPut.find('.J-del').unbind("click").on('click',function(e){
                        var th = $(this),thId = th.attr('data-id');
                        for(var i=0;i<checkItem.length;i++){
                            if(parseInt(checkItem[i].id) === parseInt(thId)){
                                if(delFun){
                                    delFun(th,thId);
                                }
                                checkItem.splice(i,1);
                            }
                        }

                        if(eleOutPutNum !== null){
                            eleOutPutNum.html(checkItem.length);
                        }
                        if(checkItem.length != maxNum){
                            eleBtn.show()
                        }
                        obj.renderSaveFile();
                    })
                };

                obj.renderSaveFile(callback);

            }

        });
    };
    obj.renderSaveFile=function (callback) {
        if(checkItem.length>0 && eleOutPut !== null ){
            // console.log(checkItem);
            var _html = template('listFiles',{list:checkItem,isRead:isRead});
            eleOutPut.html(_html);
            var checkedKey = [];
            for(var i=0;i<checkItem.length;i++){
                checkedKey.push(parseInt(checkItem[i].id));
            }
            eleOutPut.find('input[name="courseware-input"]').val(checkedKey.join(','));
            eleOutPut.find('.J-del').unbind("click").on('click',function(e){
                //debugger;
                var th = $(this),thId = th.attr('data-id');
                for(var i=0;i<checkItem.length;i++){
                    if(parseInt(checkItem[i].id) === parseInt(thId)){
                        if(delFun){
                            delFun(th,thId);
                        }
                        checkItem.splice(i,1);
                    }
                }

                if(eleOutPutNum !== null){
                    eleOutPutNum.html(checkItem.length);
                }
                if(checkItem.length != maxNum){
                    eleBtn.show()
                }
                obj.renderSaveFile();
            });
            //图片播放
            layer.photos({
                photos: '.layer-photos-demo'
                ,anim: 3 //0-6的选择，指定弹出图片动画类型，默认随机（请注意，3.0之前的版本用shift参数）
            });

            //MP3播放
            $('.J-play-mp3').unbind('click').on('click',function () {
                var th = $(this),thUrl = th.attr('data-url');
                var _html = '<div class="J-audio"></div>';
                var _h = $('body').height()-400
                layer.open({
                    type:1,
                    content:_html,
                    title:false,
                    area:'450px',
                    skin:'mp3-box',
                    success:function (laydom,index) {
                        //音频
                        var _audioConfig = {
                            ele:$('.J-audio'),
                            uploadStart:function(){
                                isUpload = true
                            },callbackData:function(){
                                isUpload = false;
                            },delFun:function(){
                                isUpload = false;
                            }
                        };
                        if(thUrl !== null){
                            _audioConfig.audioUrl = thUrl;
                        }
                        var uploadrAu = new uploaderAudio();
                        uploadrAu.init(_audioConfig);
                    }
                })
            })

            //MP4播放
            $('.J-play-mp4').unbind('click').on('click',function () {
                var th = $(this),thUrl = th.attr('data-url');
                var _html = '<div style="height:498px;overflow:hidden" class="J-mp4"></div>';
                var layIndex = layer.open({
                    type:1,
                    scrollbar:false,
                    content:_html,
                    title: false,
                    area: ['800px', '498px'],
                    success:function (layero,index) {
                        setTimeout(function() {
                            $(layero).removeClass('layer-anim');
                        }, 0);
                        var play = new audios();
                        var config = {
                            videoUrl : thUrl,
                            vTitle:'ceshi',
                            ele:layero.find('.J-mp4'),
                            callbackDo:function () {
                                layero.find('#video-ctrlPanel').css('height','46px')
                            },
                            fullScreenFun:function () {
                                layer.full(layIndex)
                            },
                            cancelFullFun:function () {
                                layer.restore(layIndex)
                            }
                        }
                        play.init(config)
                    }
                })

            })

            //pdf预览
            $('.J-pdf-view').unbind('click').on('click',function(e){
                var th = $(this), id = th.attr('data-id');
                var iou_url = "/course/material/"+ id +"/imgs";
                var iou_type = "get",iou_params = {};
                var callback = function (data) {
                    var imgList =  data.data.urlList;
                    if (imgList && imgList.length>0 ){
                        var _htmls = template('pdfTpl',{ list:imgList });
                        var layIndex = layer.open({
                            type:1,
                            scrollbar:false,
                            content:_htmls,
                            title: false,
                            area:['96%','96%'],
                            success:function (layero,index) {
                                // layer.full(index)
                                var mySwiper = new Swiper ( $(layero).find('.swiper-container'), {
                                    slidesPerView : 1,
                                    zoom : true,
                                    slidesOffsetAfter : 10,
                                    centeredSlides : true,
                                    loop: true,
                                    lazy: true,
                                    navigation: {
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    },
                                    // 如果需要分页器
                                    pagination: {
                                        el: '.swiper-pagination',
                                        type: 'fraction'
                                    }
                                })
                            }
                        })
                    }else{
                        toast.warming('资料未转换完成，暂时无法预览')
                    }

                }
                ajaxFun.icoachuSelectAjax(iou_url,iou_type,iou_params,callback)

            })

            //ppt预览
            $('.J-ppt-yulan').unbind('click').on('click',function (e) {
                var th = $(this);
                var url = th.attr('href');
                if ( ! url  ){
                    e.preventDefault();
                    toast.warming('资料未转换完成，暂时无法预览')
                }else{
                    if ( url.indexOf('http') < 0 ) {
                        e.preventDefault();
                        toast.warming('资料未转换完成，暂时无法预览')
                    }
                }
            });

        }else{
            eleOutPut.html('<p></p>');
        }
        if(callback){
            callback();
        }
    };
    obj.renderBread=function (data,iou_param) {
        var _html = template('breadTpl',{data:data});
        layDom.find('.J-bread').html(_html);
        layDom.find('.J-back').on('click',function (e) {
            //返回上一步
            e.preventDefault();
            e.stopPropagation();
            if(folderAry.length === 1 ){
                folderAry = [];
                iou_param.pageNumber = 1;
                iou_param.folderId = undefined;
                var callback = function (data) {
                    obj.renderList(data,iou_param);
                };
                obj.getFileList(iou_param,callback);
            }else{
                folderAry.splice(folderAry.length-1);
                iou_param.pageNumber = 1;
                iou_param.folderId = folderAry[folderAry.length-1].folderId;
                var callback = function (data) {
                    obj.renderList(data,iou_param);
                };
                obj.getFileList(iou_param,callback);

            }
        })
        layDom.find('.J-all').on('click',function (e) {
            //全部
            e.preventDefault();
            e.stopPropagation();
            folderAry = [];
            iou_param.pageNumber = 1;
            iou_param.folderId = undefined;
            var callback = function (data) {
                obj.renderList(data,iou_param);
            };
            obj.getFileList(iou_param,callback);
        })
    };
    obj.folderClick=function (iou_param) {
        iou_param.pageNumber = 1;
        //文件夹点击事件
        layDom.find('.J-folder-btn').on('click',function(){
            var th = $(this),folderId = th.attr('data-id'),
                folderName = th.attr('title'),
                _obj = {folderName:folderName,folderId:folderId};
            var iou = {
                pageSize:iou_param.pageSize,
                pageNumber:iou_param.pageNumber,
                folderId:folderId
            };
            if (folderId !== undefined && folderId !== null){
                iou_param.folderId = folderId
            }
            var callback = function (data) {
                folderAry.push(_obj);
                obj.renderList(data,iou_param);

            };
            obj.getFileList(iou,callback)
        })
    };
    obj.layeropen=function (_html,callback) {
        layui.use('layer', function(){ //独立版的layer无需执行这一句
            var layer = layui.layer; //独立版的layer无需执行这一句
            layer.open({
                type:1,
                shift: -1,
                content:_html
                ,skin:"relevance-box"
                ,title: modalTitle
                ,fixed:true
                ,scrollbar:false
                ,area: ['850px', '650px']
                ,btn:false
                ,success: function(layero, index){
                    layDom = $(layero);
                    layIndex = index;
                    $(layero).find('.mask-header').css('height',550-280);
                    $(layero).find('.empty-style').eq(1).remove();
                    if(callback){
                        callback();
                    }
                },cancel:function (index,layero) {
                    obj.resetLayer(index);
                }});
        })
    };
    obj.getPage=function (elePage,pageCount,curPage,callback) {

        layui.use(['laypage'], function(){
            var laypage = layui.laypage;
            laypage({
                cont: elePage
                ,pages:pageCount
                ,curr:curPage
                ,skin: '#FF5722'
                ,prev:'prev'
                ,next:'next'
                /* ,first:true
                 ,last:true
                 ,prev:"上一页"
                 ,next:"下一页"
                 ,skip:true*/
                ,groups: 5
                ,jump: function(obj, first){
                    if(!first){
                        callback&&callback(obj.curr);
                    }
                }

            });
        })
    };
    obj.saveClose = function (index) {
        pageNumber = 1;
        pageSize = pageSize ;
        layer.close(index);
    };
    obj.resetLayer=function(index) {
        pageNumber = 1;
        pageSize = pageSize ;
        folderAry = [];
        checkItem = [];
        //checkItem = tempData;
        if(tempData.length>0){
            for(var i=0;i<tempData.length;i++){
                checkItem.push(tempData[i]);
            }
        }
        layDom = null;
        layIndex = null;
        layer.close(index);
    };
    return obj;
};
